@font-face {
  font-family: 'chicken_sans_bold'; 
  src: url('./assets/fonts/ChickenSans-Bold.woff2') format('woff2'), url('./assets/fonts/ChickenSans-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'chicken_sans'; 
  src: url('./assets/fonts/ChickenSans-Regular.woff2') format('woff2'), url('./assets/fonts/ChickenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'chicken_sans_xlight'; 
  src: url('./assets/fonts/ChickenSans-ExtraLight.woff2') format('woff2'), url('./assets/fonts/ChickenSans-ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1 {
  margin: 0;
  line-height: 1em;
}

.container {
  width: 1080px;
  height: 2808px;
  background-color: black;
  color: white;
  font-family: 'chicken_sans_bold';
  text-align: center;
}

.display {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff7d00;
}

.display.one {
  width: 656px;
  height: 928px;
  left: 212px;
}

.display.two {
  width: 960px;
  height: 944px;
  left: 60px;
  top: 928px;
}

.display.three {
  width: 1080px;
  height: 936px;
  top: 1872px;
}

.row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin: -40px 0 0;
}

.row:first-child {
  margin-top: -60px;
}

.days {
  font-size: 350px;
  margin-top: -80px;
}

.time {
  width: 50%;
  font-size: 320px;
  text-align: right;
}

.label {
  font-size: 180px;
}

.display.one .label {
  margin-top: -40px;
}

.display.two .label {
  width: 50%;
  text-align: left;
  font-size: 220px;
  margin-bottom: 7px;
  margin-left: 10px;
}

.display.three .label {
  font-size: 465px;
  margin-top: -160px;
}