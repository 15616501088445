html {
  background-color: #000000;
  width: 1080px;
  height: 2808px;
  overflow: hidden;
}


body {
  margin: 0;
  font-family: 'chicken_sans_bold' 'Helvetica Neue', sans-serif;
  font-style: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
